<template>
  <div>
    Example component 2
  </div>
</template>

<script>
export default {
  name: 'ExampleComponent2',
};
</script>

<style scoped>

</style>
